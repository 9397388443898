
<LoginScreen id="my-login-screen" bind:this={loginPopup}>
    <View>
      <Page loginScreen  style="background-color: var(--f7-theme-color);" noToolbar>
        <Navbar transparent>
          {#if !required}
            <NavLeft>
            <Link style="color: #000" iconIos="f7:xmark"  iconAurora="f7:xmark" iconMd="material:close" on:click={close}/></NavLeft>
          {/if}
        </Navbar>
        <LoginScreenTitle>
        <figure>
          {#if logo}
          <img src={logo} alt={name} />
          {/if}
          <figcaption>{title}</figcaption>
        </figure>
        </LoginScreenTitle>
        <slot name="headingContent"></slot>
        {#if mode == 'passwordless'}
          {#if !confirmEmailSent}
              <List form>
              <ListInput
                  type="email"
                  name="email"
                  required
                  validate
                  placeholder="Your email"
                  value={email}
                  onInput={(e) => email = e.target.value}
              />
              </List>
                <Block>
                  <Button fill color="green" large round onClick={() => sendEmailConfirm()}>Continue</Button>
                  <p>
                    <Button fill color="blue" round on:click={loginFacebook}>Continue with Facebook</Button>
                  </p>
              </Block>
              <BlockFooter>
              By signing in, you accept our privacy policy, terms of service, and our cookie policy.
              </BlockFooter>
          {:else}
              <BlockTitle style="text-align:center">Please check your email to complete sign in.</BlockTitle>
              <Block style="min-height:290px;text-align:center">
                  <Icon material="email" color="green" size="128"></Icon>
              </Block>
          {/if}
        {:else}
           <List form>
              <ListInput
                  type="email"
                  name="email"
                  required
                  validate
                  placeholder="Your email"
                  value={email}
                  onInput={(e) => email = e.target.value}
              />
               <ListInput
                  type="password"
                  name="password"
                  required
                  validate
                  placeholder="Your password"
                  value={password}
                  onInput={(e) => password = e.target.value}
              />
              </List>
                <Block>
                  <Button fill color="green" large round onClick={() => confirmLogin()}>Continue</Button>
              </Block>
        {/if}
      </Page>
    </View>  
</LoginScreen>

<script>
import {createEventDispatcher} from 'svelte'
import { userToken,loginStatus,user,authInit,authChanged,openPopup,gotEmailAuthLink,savedEmail,loginRequired } from './store'
//import { auth } from './firebase'
import { onMount } from 'svelte';
import { firestoreLoaded,firebaseAuthLoaded,fbVersion,firestoreInstance } from '../firebase/store'
import {getUserToken} from './core'
let name;
export let logo;
export let show=false;
export let appUrl;
export let userAnonymous=false;
export let title;
export let required=false;
export let mode="passwordless";




$: if ($firestoreLoaded){
  if (typeof firebase === 'object' && typeof firebase.auth === 'object'){
    onFirebaseAuthLoaded();
  }else{
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://www.gstatic.com/firebasejs/${$fbVersion}/firebase-auth.js`;
        script.onload=onFirebaseAuthLoaded;
        document.body.appendChild(script);
  }
}

function onFirebaseAuthLoaded(){
    firebaseAuthLoaded.set(true);

    auth=firebase.auth();
    authInit.set(true);
    auth.onAuthStateChanged(function(userAuth) {
       
      console.log("onAuth",userAuth)
        authChanged.set(true);

        if (userAuth) {
            //metadataRef = $firestoreInstance.ref('metadata/' + userAuth.uid + '/refreshTime');
            getUserToken()
            .then((token)=>{
              if (token)
                userToken.set(token);
             })
          //if (!$userToken){
         
          //}else{
              loginStatus.login();

          //}

            user.update(()=> userAuth);

           // Save token every hour

          close();
          dispatch('loggedIn',userAuth);


          //store.commit('loginStatus',true);
          //store.commit('setUser',user);
         
           ver="v1.0.3L";
        } else {  
          console.log("not logged in")
          loginStatus.logoff();
           ver="v1.0.3NO";
          if (show)
            open();
          // not signed in
          //store.commit('loginStatus',false);
          //store.commit('setUser',null);
        }
    });
}


import {
    f7,
    f7ready,
    View,
    Page,
    Link,
    Block,
    BlockTitle,
    Icon,
    Navbar,NavLeft,NavRight,
    LoginScreen,
    LoginScreenTitle,
    List,
    ListInput,
    ListButton,
    Button,Row,Col,
    BlockFooter
  } from 'framework7-svelte';



let loginInProgress=false;
const dispatch = createEventDispatcher();

let ver='v1.0.4';
let loginPopup;


let auth;

$: if ($openPopup){
      if (!loginInProgress)
        loginPopup.instance().open();
}

//$: if (!$userToken && $loginStatus){
//          getUserToken((token)=>{
//          console.log("got token")
//          userToken.set(token);
//          })
//  
//}

let email = '';
let password='';

let confirmEmailSent=false;
let convertCredential=null;


function sendEmailConfirm(){
    if (email){
       f7.preloader.show();

       if (!appUrl) appUrl="http://localhost:8080"
    
        var actionCodeSettings = {
          //url: 'https://myfun.party',
         //url: 'https://dev-party-planner.firebaseapp.com',
         //url: 'http://localhost:5000',
         url: appUrl,
          handleCodeInApp: true
        }
            
        auth.sendSignInLinkToEmail(email, actionCodeSettings)
        .then(function() {
          savedEmail.set(email);
          confirmEmailSent=true;
          f7.preloader.hide();
          // The link was successfully sent. Inform the user. Save the email
          // locally so you don't need to ask the user for it again if they open
          // the link on the same device.
        })
        .catch(function(error) {
          f7.dialog.alert(error);
          // Some error occurred, you can inspect the code: error.code
        });   
    }
   
}

function confirmLogin(){
   if (email){
       //f7.preloader.show();
      auth.signInWithEmailAndPassword(email, password).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/wrong-password'){
          f7.dialog.alert("Email and/or password was incorrect. Please try again.")
        }else{
           if (errorCode == 'auth/too-many-requests'){

           }else{
              f7.dialog.alert("Unable to login at this time. Please try again later.")
           }
        }
      // ...
      });
      /*auth.createUserWithEmailAndPassword(email, password).catch(function(error) {
         f7.dialog.alert(error.message)
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
      });
      */

   }
  
}

function close(){
  loginPopup.instance().close();
  openPopup.set(false);
}
function open(){
  loginPopup.instance().open(true);
  f7.preloader.hide();
}

$: if ($user){
  if (convertCredential){
      $user.linkWithCredential(convertCredential)
            .then((userCred)=>{
            })
            .catch((e)=>{
              console.log("something went wrong with link credential ****************",e)
            })
  }
}

function SigninWithEmailLink(){
   auth.signInWithEmailLink($savedEmail, window.location.href)
    .then(function(result) {
      //let user=result.user;
       window.history.pushState({}, f7.name, "/");
      // f7.views.current.router.navigate('/home/',{reloadCurrent: true,pushState:false});
        close();
    })
    .catch(function(error) {
      f7.dialog.alert('Oops! Something went wrong. Please try your request again.');
      f7.dialog.alert(error)
      window.history.pushState({}, f7.name, "/");      
      f7.views.current.router.navigate('/home/',{reloadCurrent:true,pushState:false});
    });
}

function handleSignin(){
  // console.log("login.svelte","handleSignIn",$savedEmail,window.location.href)
    // converts anonymous accounts only
    window.setTimeout(()=>{
        if ($user){
          // Chech if user already created as anonymous
          if ($user.isAnonymous){
              convertCredential = firebase.auth.EmailAuthProvider.credentialWithLink(
              $savedEmail, window.location.href);
              auth.currentUser.linkWithCredential(credential)
              .then((userCred)=>{
                var user = usercred.user;
                 console.log("Anonymous account successfully upgraded", user);
              })
              .catch((e)=>{
                console.log("something went wrong with link credential ****************",e)
              })
          }
        }else{
          // Handle new user email auth
          SigninWithEmailLink()
        }
    },2500)
   

    
    /*
    let authListener=null;
      authListener=auth.onAuthStateChanged(function(userAuth) {
        if (userAuth.user.isAnonymous){
           auth.currentUser.linkWithCredential(credential)
            .then((userCred)=>{
              var user = usercred.user;
            console.log("Anonymous account successfully upgraded", user);
           
            })
            .catch((e)=>{
              console.log("something went wrong with link credential ****************",e)
            })
        }else{
           auth.removeAuthTokenListener(this.authListener);
        }
        
      });
    */
   

    /*
    auth.signInWithEmailLink($savedEmail, window.location.href)
    .then(function(result) {
      let user=result.user;
        let credential = firebase.auth.EmailAuthProvider.credentialWithLink(
        $savedEmail, window.location.href);

        user.linkWithCredential(credential)
        .then((userCred)=>{
           var user = usercred.user;
         console.log("Anonymous account successfully upgraded", user);
        })
        .catch((e)=>{
          console.log("something went wrong with link credential ****************",e)
        })

       window.history.pushState({}, f7.name, "/");
        close();
    })
    .catch(function(error) {
      f7.dialog.alert('Oops! Something went wrong. Please try your request again.');
      f7.dialog.alert(error)
      window.history.pushState({}, f7.name, "/");                
    });
    */
}

function loginFacebook(){
  var provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('email');
  provider.addScope('user_photos');
  auth.signInWithRedirect(provider);

}

onMount(() => {
loginRequired.set(required);

 
    f7ready(() => {
      console.log("--login mounted--")
      name=f7.name;
    });
})


</script>