<Page name="home">
  <!-- Top Navbar -->
  <Navbar large sliding={false}>
    <NavTitle sliding>{f7.name}</NavTitle>
    <NavRight>
      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" />
    </NavRight>
    <NavTitleLarge>{f7.name}</NavTitleLarge>
  </Navbar>
  {#if tables.length}
    <BlockTitle large>12 drinks today</BlockTitle>
    <Block>
      <Row class="dbheading"> 
        <Col><Icon f7="qrcode" size="32"></Icon><p>10 Scans</p></Col>
        <Col><Icon material="local_bar" size="32"></Icon><p>2 pending drinks</p></Col>
        <Col><Icon material="check" color="green" size="32"></Icon><p>12 competed</p></Col>

      </Row>
    </Block>
    <BlockTitle>Tables</BlockTitle>
    <Block>
    <Row>
      {#each tables as t}
        <Col width="50">
        <Chip on:click={() => clickTable(i)} style="width:100%" outline={t.outline} iconSize="32" iconMd="f7:qrcode" iconIos="f7:qrcode" color={t.color} text={`Table ${t.tableNo} (${t.server})`} mediaBgColor={t.color}></Chip>
        </Col>
      {/each}
    </Row>
    </Block>
    <BlockTitle>Pending drinks</BlockTitle>
    <Block>

      <Swiper navigation params={{speed:500, slidesPerView: 3, spaceBetween: 20}}>
        {#each pending as d}
          <SwiperSlide><img alt={d.name} width="80" src={d.pic + '/preview'} />{d.name}</SwiperSlide>
        {/each}
      </Swiper>
      
    </Block>
  {/if}
  
</Page>
<script>
import {onMount} from 'svelte'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
import {getTables,locTables,locId,initUser} from '../js/core'

let startcode=1000;
let endcode=1015;
let tcodes=[];
let pending=[
  {id: "11007",name: 'Margarita',pic:'https://www.thecocktaildb.com/images/media/drink/5noda61589575158.jpg',notes: 'Tequila: Jose Cuervo Gold'},
  {id: "11000",name: 'Mojito',pic:'https://www.thecocktaildb.com/images/media/drink/3z6xdi1589574603.jpg',notes: 'Rum: Bacardi Light Rum'},
  {id: "15841",name: 'Mojito #3',pic:'https://www.thecocktaildb.com/images/media/drink/vwxrsw1478251483.jpg',notes: 'Rum: Captain Morgan'},
  {id: "11001",name: 'Old Fashioned',pic:'https://www.thecocktaildb.com/images/media/drink/vrwquq1478252802.jpg',notes: 'Well'}

  ];

var lid="";
let tables=[];
let activeTables=[];

onMount(()=>{
 // console.log("initUser")
  //initUser();
  if (!$locId){
     getTables()
      .then((d)=>{
        console.log("got tables")
        if (d){
             tables=d.tables;
           if (d.lid){
              lid=d.lid;
            locId.set(lid);
            locTables.set(tables);
          }
        }
      })
  }else{
        console.log("empty")

    lid=$locId;
    tables=$locTables;
  }

  /*
  // Get location table list.
  let dbRef=$firestoreInstance.collection(`locations/${lid}/tables`)
  .get()
  .then((qs)=>{
    if (!qs.empty){
      let ts=[];
      qs.docs.forEach((doc)=>{
        let obj=doc.data();
        obj.id=doc.id;
        obj.color="default";
        obj.outline=true;
        ts.push(obj);
      });
      tables=ts;
      setupActiveTableListener();
      // Setup snapshot listener for each table.
     
    }
  })
  */
})

$: {
  if (activeTables.length){
    tables.forEach((t)=>{
      let fi=activeTables.findIndex((at)=>{
        return at.tableNo == t.tableNo
      });
      if (fi > -1){
        t.color="yellow"
      }
    })
    tables=tables; // update svelte

  }
}

function setupActiveTableListener(){
    $firestoreInstance.collection(`activeTbl`).where('lid','==',lid).where('isClosed',"==",false)
          .onSnapshot(function(qs) {
            let aTables=[];
             qs.forEach(function(doc) {
                let obj=doc.data();
                obj.id=doc.id;
                aTables.push(obj);
             });
             activeTables=aTables;
             console.log("got active tables",activeTables)
    });
}

for(var i=startcode; i <= endcode; i++){
  let t={code: i, table: 100 + i,server: 'Jane',outline:true,color: "default"};
  tcodes.push(t);
}

tcodes[4].color="green"

  import {f7,
    Page,
    Navbar,
    NavLeft,Chip,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,Icon,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,
    Button,Swiper,SwiperSlide
  } from 'framework7-svelte';

  function clickTable(i){

  }
</script>