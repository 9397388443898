<Page name="home" on:pageAfterIn={afterIn}>
  <!-- Top Navbar -->
  <Navbar large sliding={false}>
    <NavTitle sliding>Tables</NavTitle>
    <NavRight>
      <Link iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu" panelOpen="right" />
    </NavRight>
    <NavTitleLarge>Tables</NavTitleLarge>
  </Navbar>
  <Block>
    <Row>
    {#if tables}
      {#each tables as t,i}
          <Col width="33" on:click={(e)=>clickTable(i)}>
              <div class="table" class:color-yellow={t.color=='yellow'} class:color-green={t.color=='green'} class:color-red={t.color=='red'} class:calling={t.isCalling}>
                  <h1>{t.tableNo}</h1>
                  <p>{t.server}</p>
                  {#if t.isActive}
                      <p>{timeDiff(t.created)}</p>
                  {/if}
              </div>
          </Col>
      {/each}
      {/if}
    </Row>
  </Block>

  {#if selTable}
  <Popup id="popup-table" on:popupOpened={popupTableOpened}>
    <div class="view view-init">
     <Page pageContent={false}>
      <Navbar title={'Table ' + selTable.tableNo}>
        <NavRight>
          <Link popupClose>Close</Link>
        </NavRight>
      </Navbar>
      <Toolbar top tabbar>
         <Link tabLink="#tbl-current" tabLinkActive>Pending</Link>
        <Link tabLink="#tbl-past">Completed</Link>
      </Toolbar>
       
      <Tabs>
        <Tab id="tbl-current" class="page-content" tabActive>
          <Block>
            <figure class="fig-profile text-align-center">
                    <center>
                    {#if ownerPic}
                    <div class="circle-profile">
                      <img src={ownerPic} alt="" />
                    </div>
                    {:else}
                        <div class={`avatar-small avatarsmall${ownerAvatar}`} ></div>
                    {/if}
                    </center>
                    <figcaption>
                      {owner}
                    </figcaption>
            </figure>
          </Block>
          <Block>
            <Button color="red" large on:click={closeMe}>Close table</Button>
          </Block>
          <Block>
                <List mediaList>
                  <ListItem divider title="Pending drinks"></ListItem>
                  {#each requests as r,idx}
                    <ListItem accordionItem={true} accordionItemOpened={false}
                      link="#"
                      title={r.drink}
                      subtitle={'for ' + r.user}
                    > 
                      <AccordionContent>
                        <Block>
                            <Card>
                                <CardContent><div>Request for a {@html r.descr}</div>
                                {#if r.ingred}
                                    <Swiper class="swiper-ingred" params={{slidesPerView:"3",spaceBetween:2}}>
                                      {#each r.ingred as ig,index}
                                      <SwiperSlide>
                                            <IFeature w="40px" h="40px" title={ig} id={index} pic={'https://img.imageboss.me/ing/width/40/' + ig + '-Small.png'} lazy></IFeature>
                                      </SwiperSlide>
                                      {/each}
                                    </Swiper>
                                  {/if}
                                  {#if r.status && r.status == "pending"}
                                    <Icon material="schedule" color="blue" />&nbsp; Waiting for approval
                                  {/if} 
                                </CardContent>
                                <CardFooter>
                                  <Link color="red" on:click={()=> rejectDrink(idx)}>Reject</Link>
                                  <Link color="green" on:click={() => acceptDrink(idx)}>Approve</Link>
                                </CardFooter>
                            </Card>
                        </Block>
                      </AccordionContent>
                    <div slot="media">
                    {#if !r.internal}
                        <img src={`https://img.imageboss.me/cocktails/width/100/${r.pic}/preview`} width="50px" alt="" />
                    {:else}
                        <img src={`https://img.imageboss.me/justdrinks/width/100/${r.pic}`} width="50px" alt="" />
                    {/if}
                    </div>
                    </ListItem>
                    {/each}
                </List>
                <p>Table scanned <span>{timeDiff(selTable.created)}</span> ago</p>

                {#if selTable.isCalling}
                    <Button fill class="calling" color="red" large round iconMaterial="phone" on:click={answerCall}>Table is requesting server...</Button>
                {/if}

              </Block>
        </Tab>
        <Tab id="tbl-past" class="page-content">
          <Block>Hello</Block>
        </Tab>
      </Tabs>
      </Page>
      </div>
  </Popup>
  {/if}
</Page>

<style>
.table {
    color: var(--f7-theme-color);
    width: calc(33vw-8px);
    height: 130px;
    text-align:center;
    border: 2px solid var(--f7-theme-color);
}
.table h1 {
    font-size: 3em;
    margin:0;
}
.table p {
    margin:0px;
}

.numpad {
		margin: 0 auto;
		margin-top: auto;
		max-width: 640px;
		width: 100%;
	}

	.otp-digit {
		align-items: center;
		background-color: #F5F5F5;
		color: #222222;
		border-radius: 50%;
		display: inline-flex;
		font-size: 24px;
		font-weight: bold;
		height: 32px;
		justify-content: center;
		margin: 0 4px;
		padding: 8px;
		vertical-align: top;
		width: 32px;
	}

	:global(.otp-digit.filled) {
		color: #FFFFFF;
    }

    :global(.md .otp-digit.filled) {
		background-color: var(--md-color-blue);
	}
    
    .keypad-button {
		background-color: transparent;
	}

	.keypad-button:before {
		display: none;
	}

	.keypad-button:after {
		display: none;
	}

	.keypad-button-letters {
		display: none;
	}


</style>
<script>
var lid="";

let tables=[];
let activeTables=null;
let selTable=null;
let gotCode=null;
let digits=new Array(4);
let code="8888";
let numpadEl;
let ownerAvatar="";
let ownerPic;
let owner="";
let requests=[];


import {getActiveTables,closeTable,getTableDetails,updateDrinkStatus} from '../js/core'
 import IFeature from '../components/dts-shared-components/img-feature.svelte'
function afterIn(){
}
function rejectDrink(i){
   let r=requests[i];
    updateDrinkStatus(selTable.activeId,r.id,"rejected")
        .then(()=>{
          console.log("drink rejected.")
        })
}
function acceptDrink(i){
  let r=requests[i];
  console.log(selTable.activeId,r.id)
     updateDrinkStatus(selTable.activeId,r.id,"accepted")
        .then(()=>{
          console.log("drink accepted.")
        })
}

$: {
  if ($locTables){
      tables=$locTables;
    lid=$locId;
    setupActiveTableListener();
  }
}

onMount(()=>{
  // Get location table list.
  /*
  let dbRef=$firestoreInstance.collection(`locations/${lid}/tables`)
  .get()
  .then((qs)=>{
    if (!qs.empty){
      let ts=[];
      qs.docs.forEach((doc)=>{
        let obj=doc.data();
        obj.id=doc.id;
        obj.color="default";
        obj.outline=true;
        ts.push(obj);
      });
      tables=ts;
      setupActiveTableListener();
      // Setup snapshot listener for each table.
     
    }
  })
*/

    f7ready(()=>{
    })

})


$: {
  if (activeTables){
    
    tables.forEach((t)=>{
     // let fi=activeTables.findIndex((at)=>{
     //   return at.tableNo == t.tableNo
     // });
     let at=activeTables[t.tableNo];
     console.log(at)
     if (at && !at.isClosed){
        t.color="";
          t.color="green"
        t.isActive=true;
         t.activeId=at.id;
        t.created=at.created;
        t.lastActivity=at.lastActivity;
        t.isCalling=at.isCalling
        if (t.isCalling) t.color="red";
     }
    });
    tables=tables; // update svelte
  }
}

function popupTableOpened(){
  let id=selTable.activeId;
  console.log("open popup")
  getTableDetails(id)
  .then((d)=>{
    ownerPic=null;
    ownerAvatar=null;
    owner="";
    if (d.users){
      owner=d.users[0].name;
      if (d.users[0].pic)
        ownerPic=d.users[0].pic;
      else
        ownerAvatar=d.users[0].avatar;
    }
    console.log(d);
    requests=d.requests;
  })
}

let atl;
function setupActiveTableListener(){
  console.log("Setup listener")
   if (atl)
    atl();

  atl=$firestoreInstance.collection('tables').doc(lid)
  //  $firestoreInstance.collection(`activeTbl`).where('lid','==',lid).where('isClosed',"==",false)
    .onSnapshot(function(doc) {
      
      if (doc.exists){
        let data=doc.data();
        data.id=doc.id;
       activeTables=data;
       console.log("active tables",data)
      }

      // Get active tables
      /*
      getActiveTables()
      .then((tbls)=>{
        console.log("--tbls",tbls)

        activeTables=tbls;
      })
      */
           // let aTables=[];
           //  qs.forEach(function(doc) {
           //     let obj=doc.data();
           //     obj.id=doc.id;
           //     aTables.push(obj);
           //  });
           //  activeTables=aTables;
           //  console.log("got active tables",activeTables)
    });
}

let intervalTimer;
function createTimerForTableTime(){
    if (!intervalTimer){
        intervalTimer=window.setInterval(()=>{
             tables.forEach((t)=>{
                
             });
        },60000)
    }
}

function timeDiff(startDate){
    if (startDate)
    return formatDistanceToNow(new Date(startDate.seconds * 1000),{includeSeconds: false})
    else
      return "";
  //return differenceInMinutes(new Date(),new Date(startDate.seconds * 1000))

}

function clickTable(i){
    selTable=tables[i];

    console.log("** sel table",selTable)
    tick()
    .then(()=>{
      if (selTable.isActive)
        f7.popup.open('#popup-table');

    })
}

function closeMe(){
    f7.dialog.confirm("This will close and free up the table for a new customer. Are you sure?","Close table",()=>{
        //console.log(selTable.activeId)
        //console.log(lid)
        //$firestoreInstance.collection(`activeTbl`).doc(selTable.activeId).set({isClosed: true,lid: lid},{merge:true});
        closeTable(selTable.activeId)
        .then(()=>{
          console.log("table closed.")
        })

        f7.popup.close('#popup-table');
    })
}

function answerCall(){
    f7.dialog.confirm('Click OK to close this call when server has been to the table.',"Close call",()=>{
        $firestoreInstance.collection(`activeTbl`).doc(selTable.activeId).set({isCalling: false,lid: lid},{merge:true});
    })
    f7.popup.close('#popup-table');
}

import {locTables,locId} from '../js/core'
import {onMount,tick} from 'svelte'
import {user} from '../components/dts-shared-components/auth/store'
import {differenceInMinutes,differenceInHours,formatDistanceToNow} from 'date-fns'
import {firestoreInstance} from '../components/dts-shared-components/firebase/store'
 import {f7,f7ready,
    Page,
    Navbar,
    NavLeft,Chip,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Toolbar,
    Block,Icon,
    BlockTitle,
    List,
    ListItem,
    Row,
    Col,Popup,
    Button,Swiper,SwiperSlide,AccordionContent,Card,CardContent,CardHeader,CardFooter,Tabs,Tab,View,
  } from 'framework7-svelte';
</script>