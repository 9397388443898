
<Link text="Logout" on:click={logout}></Link>

<script>
import { firebaseAuthLoaded } from '../firebase/store'
function logout(){
    if ($firebaseAuthLoaded){
        let auth=firebase.auth();
        auth.signOut();
        f7.dialog.alert("You have been logged out.")
    }
}
import {
    f7,
    Link
  } from 'framework7-svelte';
 </script>