<Page name="alcohol-brands-sel">
  <Navbar title='' backLink="Back" />
  <BlockTitle medium>{alcohol} Brands</BlockTitle>
  <Block><p>Select the brands that you carry. If you are unable to find a brand, please use our Just Drinks Bot to add.</p></Block>
  <BrandChips atype={id} bname={alcohol} lid={lid}></BrandChips>

</Page>
<script>
export let f7route;


let alcohol="";
let id=f7route.params.id;
 switch(id){
        case "tequila":
          alcohol="Tequila";
          break;
        case "vodka":
          alcohol="Vodka";
          break;
        case "gin":
          alcohol="Gin";
          break;
        case "whiskey":
          alcohol="Whiskey";
          break;
        case "scotch":
          alcohol="Scotch";
          break;
         case "bourbon":
          alcohol="Bourbon";
          break;
        case "rum":
          alcohol="Rum";
          break;
        default:
          id="brandy"
          alcohol="Brandy"
          break;
      }

  import { onMount } from 'svelte';
  import BrandChips from '../../components/brand-sel.svelte'
import { f7, f7ready, Page, Navbar, BlockTitle, Block,Toolbar,Link,List,ListItem,Row,Col,Chip } from 'framework7-svelte';
import {locId} from '../../js/core'
let lid="";

 onMount(() => {
    f7ready(() => {
        if ($locId){
          lid=$locId;
          console.log("-- update got lid",lid,id)
        }

    });
  })


</script>