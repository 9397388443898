/* Define App firebase config here */
  var firebaseAppConfig = {
    apiKey: "AIzaSyA7vBTiscetujOwQfX2H02zNN07PyFx-ho",
  authDomain: "just-drinks-manager-dev.firebaseapp.com",
  databaseURL: "https://just-drinks-manager-dev.firebaseio.com",
  projectId: "just-drinks-manager-dev",
  storageBucket: "just-drinks-manager-dev.appspot.com",
  messagingSenderId: "1060254060888",
  appId: "1:1060254060888:web:9bd9c2195476825a60952d"
  };
  var fiestaConfig={
    apiKey: "AIzaSyCcJGqlNIlcgpAcN9CZwg1RSdeBS60yj_M",
    authDomain: "test.justdrinks.app",
    databaseURL: "https://fiesta-drinks.firebaseio.com",
    projectId: "fiesta-drinks",
    storageBucket: "fiesta-drinks.appspot.com",
    messagingSenderId: "737865600208",
    appId: "1:737865600208:web:5ef34b39bc1628011ae2d5",
    measurementId: "G-XJJW222VKW"
  };
export {firebaseAppConfig,fiestaConfig}
