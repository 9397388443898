<List inset mediaList id={uuid}>
    <ListItem title={data.name + ' ' + data.style}>
        <div slot="subtitle">
            {@html `${data.maker} &middot; ${data.from}`}
        </div>
        <div slot="text">
            {data.descr}
        </div>
    </ListItem>
    <ListInput
        name="year"
        label="Year"
        type="select"
        value={year-1} required
        placeholder="Please choose year..."
    >
        {#each yearList as y }
        <option value={y}>{y}</option>
        {/each}
    </ListInput>
    <ListButton title="Add wine" color="green" fill on:click={submitBrand} />
</List>
<script>
let year=new Date().getFullYear();
let yearList=[];
import {List,ListInput,ListItem,ListButton,f7ready,f7} from 'framework7-svelte'
import {onMount,createEventDispatcher} from 'svelte'
const dispatch = createEventDispatcher();
export let data;
export let uuid;


onMount(()=>{
     if (!uuid){
        console.warn('uuid is required for form to work')
    }
    for(var i=year; i > year-50; i--){
       yearList.push(i);
    }
    yearList=yearList;
})

function submitBrand(e){
    e.preventDefault();
    let valid=f7.input.validateInputs(`#${uuid}`);
    if (valid){
         var formData = f7.form.convertToData(`#${uuid}`);
         let updatedForm=data;
         updatedForm.year = formData.year;
        updatedForm.eventName="wine-management-addwine";
        dispatch('formResult',updatedForm);
    }
}

</script>
