
<div></div>
<!-- Network -->

<script>
import {onMount,createEventDispatcher} from 'svelte'
import {isOnline} from './store'
const dispatch = createEventDispatcher();


onMount(()=>{
    console.log("-- network check mounted --")
    if (navigator.onLine){
         dispatch('isOnline',{isOnline:true});
    }else{
        dispatch('isOnline',{isOnline:false});
    }
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
})

function onOnline(){
    dispatch('isOnline',{isOnline:true})
}

function onOffline(){
    dispatch('isOnline',{isOnline:false})
}

</script>