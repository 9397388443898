<Page name="wine">
  <Navbar title='Wine Manager' backLink="Back" />
  <List mediaList>
    <ListItem></ListItem>
  </List>
  <Block>

  </Block>

    <Fab position="center-bottom" text="Add" color="red" on:click={openChat}>
        <Icon ios="material:add" aurora="material:add" md="material:add"></Icon>
    </Fab>
    <PopupChat on:openListItem={openListItem} on:eventResponse={eventResponse} on:formReq={formReq} form={form} formProps={formProps} eventUrl={eventUrl} chatUrl={chatUrl} eventData={eventData} event={chatEvent} botName="Sally Bot" userName="" userToken={$userToken} lid={$locId} data={chatData} welcome={welcome} botAvatar='https://img.imageboss.me/justdrinks/width/40/hostess-icon.png'>
    </PopupChat>
</Page>
<script>
import { f7, Page, Navbar, BlockTitle, Button, Block,Toolbar,Link,List,ListItem,Fab,Icon } from 'framework7-svelte';
import PopupChat from '../components/popup-chat.svelte'
import {onMount} from 'svelte'
import {getDrinks,locId,sendChatEvent} from '../js/core'
import {userToken} from '../components/dts-shared-components/auth/store'

import AddWine from '../components/add-wine.svelte'
import ConfirmWine from '../components/confirm-wine.svelte'

let wines;
let chatEvent="";
let eventData={};
let chatData={};
let welcome=null;
let eventUrl="https://us-central1-just-drinks-manager-dev.cloudfunctions.net/chatev";
let chatUrl="https://us-central1-just-drinks-manager-dev.cloudfunctions.net/chatmsg";
 import Loadable from 'svelte-loadable'
let addFormComponent;
let show=false;

let form=null;
let formProps=null;

function openListItem(){

}
function eventResponse(e){
    console.log(e.detail);
}
function openChat(){
    console.log("open!!")
    chatEvent="wine-management";
    f7.popup.open('.b-popup');
}

function formReq(e){
    let detail=e.detail;
    if (detail.role == "add-wine"){
        form=AddWine;
        formProps=detail;
    }else{
         if (detail.role == "confirm-wine"){
              form=ConfirmWine;
                formProps=detail;
         }
    }
}

onMount(()=>{

    getDrinks('wine')
    .then((d)=>{
        //f7.dialog.alert("I got da wine!");
        console.log(d);
    })
})

</script>