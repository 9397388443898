import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';

export const firebaseLoaded=writable(false);
export const firestoreLoaded=writable(false);
export const firebaseAuthLoaded=writable(false);
export const firebaseMessagingLoaded=writable(false);
export const loaded=writable(false);


export const firebaseConfig=writable({});

export const firestoreInstance=writable(undefined);

export const firebase=writable(undefined);
export const GeoFirestoreLoaded=writable(false);
export const messaging = writable(null);
export const messagingToken=writable(null)
export const fbMessagingToken=pwritable('fbtoken','') 
export const fbVersion=writable(null);