<script>
import { onMount } from 'svelte';
import {fbVersion,firebaseLoaded,firestoreLoaded,fbMessagingToken,firebaseAuthLoaded,firebaseConfig,firestoreInstance,GeoFirestoreLoaded,firebaseMessagingLoaded,messaging,messagingPermission,messagingToken} from './store'
export let config;
//export let geoFirestore=false;
export let messageKey=null;
import {notifications} from '../notification/store.js'
export let loadScripts=false;
let token;

let version="8.0.0";
onMount(() => {
    console.log('--- firebase component mounted --')
    fbVersion.set(version);
    
        if (loadScripts){
            if (typeof firebase !== 'object'){
            let script = document.createElement("script");
            script.type = "text/javascript";
            script.src = `https://www.gstatic.com/firebasejs/${version}/firebase-app.js`;
            script.onload=onFirebaseLoaded;
            document.body.appendChild(script);
            }else{
                onFirebaseLoaded();
            }
        }else{
            onFirebaseLoaded();
            onFireStoreLoaded();
            //onFirebaseAuthLoaded();
        }
   
});

function onFirebaseLoaded(){
    console.log("-- Firebase loaded")
    firebaseLoaded.set(true);
    firebase.initializeApp(config);

    // Load Firestore
    if (typeof firebase === 'object' && typeof firebase.firestore === 'object'){
         onFireStoreLoaded();
    }else{
       let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://www.gstatic.com/firebasejs/${version}/firebase-firestore.js`;
        script.onload=onFireStoreLoaded;
        document.body.appendChild(script);
    }

}

function onFireStoreLoaded(){
    console.log("-- Firestore loaded")
    let fs=new firebase.firestore();
    //console.log("*** firestore loaded",fs);
    firestoreLoaded.set(true);

    firestoreInstance.set(fs);

}


/*
$: if ($firebaseMessagingLoaded){
        
        $messaging.requestPermission()
        .then(()=>{
            messagingPermission.set(true);
            return $messaging.getToken();
        })
        .then((token)=>{
            messagingToken.set(token)
            
            // Send token to server.
          

            
        })
}
*/

</script>