<List noHairlinesMd inset form id={uuid}>
  <ListInput name="name"
    type="text"
    placeholder="Name"
    info="Name of the wine"
    value={name}
    clearButton
  />

    <ListInput name="style"
    type="text"
    value={wineStyle}
    info="Style of wine"
    disabled="true"
  />

   <ListInput name="maker"
    type="text"
    placeholder="Maker"
    info="Maker of the wine"
    clearButton
  />

<ListInput
    name="year"
    label="Year"
    type="select"
    value={year-1}
    placeholder="Please choose..."
>
    {#each yearList as y }
    <option value={y}>{y}</option>
    {/each}
</ListInput>
<ListInput name="from"
label="From"
type="text"
placeholder="Where is this wine from?"
info="example: Napa"
required
validate
clearButton
/>
<ListInput
label="Description"
type="textarea"
placeholder="A brief description of the wine"
name="descr"
clearButton
>
</ListInput>
<ListButton title="Add wine" color="green" fill on:click={submitBrand} />
</List>
<script>
let year=new Date().getFullYear();
let yearList=[];

export let name,wineStyle,uuid;


import {List,ListInput,ListButton,f7ready,f7} from 'framework7-svelte'
import {onMount,createEventDispatcher} from 'svelte'
const dispatch = createEventDispatcher();

onMount(()=>{
    if (!uuid){
        console.warn('uuid is required for form to work')
    }
    for(var i=year; i > year-50; i--){
       yearList.push(i);
    }
    yearList=yearList;

})

function submitBrand(e){
        e.preventDefault();
    let valid=f7.input.validateInputs(`#${uuid}`);
    if (valid){
            var formData = f7.form.convertToData(`#${uuid}`);
            console.log("submit wine",formData)
            let type=`wine-${formData.style.toLowerCase()}`
            type=type.replace(' ','');

            formData.type=type;
            formData.pic=`${type}.jpg`;
            let id=`wine-${formData.name}-${formData.style}`;
            id=id.replace(' ','-');
            formData.id=id.toLowerCase();

            //let meta=JSON.stringify(formData);
            dispatch('formResult',formData);



           // console.log(formData);
            //let dbRef=$firestoreInstance.collection(`locations/${lid}/drinks`).doc('wine');
            //dbRef.get()
            //.then((d)=>{
            //    if (d.exists){
            //        return d.data()
            //    }else{
            //        return {};
        // })
            //.then((doc)=>{
        //     if (doc.list){
        //         doc.list.push(formData)
        //     }else{
        //         doc.list=[formData];
        //     }
        //     dbRef.set(doc,{merge:true})
        //     .then(()=>{
        //         f7.dialog.alert("Your wine has been added.")
        //     })
        // })
    }
}

</script>