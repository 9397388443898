<!-- Page content -->
  <Popup class="b-popup" on:popupOpened={bPopupOpened} onPopupClosed={() => popupBotOpened = false} opened={popupBotOpened}>
     <Page messagesContent={true} withSubnavbar>
     <Navbar>
    <NavLeft><img style="margin-left: 6px" src={botAvatar} width="40" alt=""></NavLeft>
<NavTitle>Ask {botName}</NavTitle>
  <NavRight>
              <Link popupClose>Close</Link>
            </NavRight>
</Navbar>
            
              <Messagebar
          placeholder={placeholder}
          bind:this={messagebarComponent}
          value={messageText}
          onInput={(e) => messageText = e.target.value}
        >
        <a href="#ms" class="link icon-only" slot="inner-end" on:click={sendMessage}>
            <Icon
              ios="material:send"
              aurora="material:send"
              md="material:send"
            />
          </a>
        </Messagebar>
      <PhotoBrowser photos={photos} theme="dark" bind:this={standaloneDark} />
  
      <Messages>
        <MessagesTitle><b>{format(initialStart,'EEEE, MMMM do hh:mm bb')}</b></MessagesTitle>
        {#each messagesData as message, index (index)}
          {#if message.text || message.image}
          <Message
            type={message.type}
            image={message.image}
            name={message.name}
            avatar={message.avatar}
            first={isFirstMessage(message, index)}
            last={isLastMessage(message, index)}
            tail={isTailMessage(message, index)}
            htmlText={message.text}
            on:click={clickBubble(message.imageIdx)}
          />
          {:else}
            {#if message.buttons}
              <Block><Row>
              {#each message.buttons as btn}
                <Col width="90"><Button on:click={doSuggestion(`${btn}`)} outline class="buttonSuggest">{btn}</Button></Col>
              {/each}
              </Row></Block>
            {:else}
              {#if message.listButtons}
                <List inset>
                {#each message.listButtons as btn,lbIndex}
                    <ListItem title={btn.text} href="#" on:click={() => dispatch('openListItem',btn)}>
                        <div slot="media" on:click={(e) => {e.stopPropagation(); standaloneDark.open(btn.photoIdx)} }>
                            <img src={'https://img.imageboss.me/cocktails/width/80/' + btn.pic} alt="" />
                        </div>
                    </ListItem>
                {/each}
                </List>
              {:else}
                {#if message.form}
                 <svelte:component this={form} {...formProps} on:formResult={formResult} />
                {/if}
              {/if}
            {/if}
          {/if}
        {/each}
        {#if typingMessage}
          <Message
            type="received"
            typing={true}
            first={true}
            last={true}
            tail={true}
            header={`${typingMessage.name} is typing`}
          ></Message>
        {/if}
      </Messages>
     
     </Page>
  </Popup>
<style>
.subnavbar .title:first-child {
    margin-left: 0;
}
.subnavbar .title img {
    float: left;
    margin-top: 4px;
}
.subnavbar .title {
  line-height:48px;
}
</style>
<script>
import { f7,f7ready, Page,Navbar, Row,Col,NavRight,NavLeft,NavTitle,Button,List,ListItem,Messages,Message,Messagebar,MessagesTitle,hotoBrowser, PhotoBrowser,Icon, Block,Popup,Link} from 'framework7-svelte';
import { onMount, onDestroy,tick,createEventDispatcher } from 'svelte';
import {initStart,sendToBot,sendEventToBot } from './dts-shared-components/chatbot/scAPI.js'
import {structProtoToJson } from '../js/structJson.js'
import { v4 as uuidv4 } from 'uuid';

let messagebarComponent;
let messagebarInstance;
let messageText='';
let messagesData=[];
let typingMessage = null;
let responseInProgress = false;
let standaloneDark;
let photos=[];
let updated=true;
let placeholder=`How can I help you?`
let voices = window.speechSynthesis.getVoices();
let started=false,response=null;
let popupBotOpened=false;
let session='';
let logging=false;
let currentForm=null;

export let botName="Chatbot";
export let botLang="en-US";
export let userName="";
export let botAvatar;
export let botVoice=6; //0=male,4=female,5=male,6=female spanish
export let cmodule="restaurant",action="welcome";
export let welcome="welcome";
export let suggest;
export let lid="";
export let uid="";
export let data;
export let userToken="";

export let eventData;
export let event;
export let eventUrl;
export let chatUrl;
export let form=null;
export let formProps=null;

let sessionStart,initialStart=new Date();
import {differenceInMinutes,getUnixTime,format} from 'date-fns'

let firstTime=true;

const dispatch = createEventDispatcher();

onMount(() => {
    f7ready(() => {
      messagebarInstance = messagebarComponent.instance();
      
    });
});

function formResult(e){
  let detail=e.detail;
  eventData=detail;
  let ename=eventData.eventName;
   sendEvent(ename)
     .then((r)=>{
       //dispatch('eventResponse',r)
    })
  //console.log("---- Got a form result!!! Yea baby!!!!",detail)

}

$: {
  if (response) {
      typingMessage = null;
      tick().then(()=>{
          renderResponse(response)
      })
  }
   if (suggest){
     if (!started){
      window.setTimeout(()=>{
          if (suggest)
            doSuggestion(suggest)
            suggest=null;
      },2500)
     }else{
         doSuggestion(suggest)
         suggest=null;
     }
  
   }
   if (event){
     let eventc=event;
     event=null;
     // Send event to bot
     sendEvent(eventc)
     .then((r)=>{
       dispatch('eventResponse',r)
     })
   }
 // if ($position){
 //   console.log("--Position updated?--")
 // }

}

function sendEvent(name){
  let smeta=JSON.stringify(eventData);
   typingMessage={
              name: botName
  }
  return sendEventToBot(cmodule,session,name,smeta,userToken,eventUrl)
  .then((r)=>{
     if (!session)
      session=r.session;
    console.log("got session",r.session,name)

    response=r;
  })
}

 function sendMessage(){
    let text = messageText.replace(/\n/g, ' ').trim();
    //let text=messageText.trim();

    //text=text.replace(/[^\x00-\x7F]/g, "");
    if (text.length){
        messagesData=[...messagesData,{
       text: text,
       type: 'sent'
      }];
      typingMessage={
          name: botName
      }
      tick().then(()=>{
        messageText='';
        sendToBot(cmodule,session,text,userToken,chatUrl).then((r)=>{
          // Update response will trigger UI
          response=r;
          // reset session timer
          sessionStart=new Date();
         })
   
      })
    }
}

/*************************************************************************/
/* renderResponse
***************************************************************************/
function renderResponse(resp){
var d = new Date();
let time=d.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3");
  try {
    resp.result.forEach(msg => {
      if (logging == "true") console.log(msg);
      if (msg.message == 'payload'){
        console.log("got payload!");
        let dJson=structProtoToJson(msg.payload);
        if (dJson.list){
          dJson.list.forEach((d)=>{
              photos=[...photos,{url:`https://img.imageboss.me/cocktails/width/700/${d.pic}`, caption: d.text}]
              d.photoIdx=photos.length-1;
          })
          messagesData = [...messagesData, {
          type: 'received',
          name: botName,
          t:time,
          listButtons: dJson.list
          }]
        }else{
          // TODO:Send event to parent that we got a payload
          if (dJson){
            if (dJson.type == 'form'){
              dJson.uuid=uuidv4();
              dispatch('formReq',dJson);
              console.log("json",dJson)
               messagesData = [...messagesData, {
              type: 'received',
              name: botName,
              t:time,
              form: true
             }]
            }
          }
        }
      }else{
        if (msg.message == 'text'){
          messagesData = [...messagesData, {
            text: msg.text.text,
            type: 'received',
            name: botName,
            t:time,
            buttons:null,
            imageIdx:-1,
          }];
          speakIt(msg.text.text[0])
        }else{
        //	let replyButtons='';
          if (msg.message == 'quickReplies'){
            messagesData = [...messagesData, {
            type: 'received',
            name: botName,
            t:time,
            buttons:msg.quickReplies.quickReplies
          }];
          }else{
            if (msg.message == 'card'){
              photos=[...photos,{url:msg.card.imageUri, caption: msg.card.title}]
                
                messagesData = [...messagesData, {
                type: 'received',
                name: botName,
                t:time,
                text: msg.card.title,
                image:msg.card.imageUri,
                imageIdx: photos.length-1,
              }];
              speakIt(`Here is a ${msg.card.title}`)
              
            }
          }
        }
      //	}
      }
    });
     response=null;
  }
  catch(error){
    response=null;
  }
   
}
function doSuggestion(txt){
    let stripText=txt.replace(/[^\x00-\x7F]/g, "");
    if (stripText.length){
      messagesData=[...messagesData,{
       text: stripText,
       type: 'sent'
      }];
      typingMessage={
          name: botName
      }
      tick().then(()=>{
        sendToBot(cmodule,session,stripText,userToken,chatUrl).then((r)=>{
        // Update response will trigger UI
        response=r;
         })
   
      })
    }
}

 function fabSuggest(){
    doSuggestion('Suggest a drink');
  }
   function fabLucky(){
    doSuggestion('Surprise me!')
  }
  function fabPopular(){
    doSuggestion('Popular Drinks')
  }
function speakIt(text){
    //console.log("speakIt",text)
    try {
      let speech = new SpeechSynthesisUtterance(text);
      if (speech){
        voices = window.speechSynthesis.getVoices();
        if (voices.length){
          speech.voice = voices[botVoice];
          speech.voiceURI='native'
          speech.lang=botLang;
          window.speechSynthesis.speak(speech);
        }
      }
    }
    catch {

    }
}
function isFirstMessage(message, index) {
    const previousMessage = messagesData[index - 1];
    if (message.isTitle) return false;
    if (!previousMessage || previousMessage.type !== message.type || previousMessage.name !== message.name) return true;
    return false;
}
function isLastMessage(message, index) {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
    return false;
  }
function isTailMessage(message, index) {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name) return true;
    return false;
}
function clickBubble(bi){
  if (photos.length){
    if (bi > -1)
      standaloneDark.open(bi);
  }
}


function bPopupOpened(){
  popupBotOpened=true;

  let curTime=new Date()
  if (sessionStart){
    let m=differenceInMinutes(curTime,sessionStart);
    if (m > 20){
      session=null;
      started=false; // reset
    }
  }

  if (!started){
          typingMessage={
              name: botName
          }
          // TODO: Lookup fav alcohol list based on uid, store in context
          if (welcome)
            initStart(cmodule,welcome,userName,lid,uid,JSON.stringify(data),true,userToken)
            .then((r)=>{
                      started=true;
                      session=r.session;
                      response=r;
                      sessionStart=new Date();
              });
    }

}



</script>