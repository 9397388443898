<Page noNavbar noToolbar class="page-theme-color" noSwipeback name="splash" onPageInit={onPageInit}>
	<Block class="content-align" color="default">
		<div class="item text-align-center">
            {#if logo}
			<img width="250" src={logo} alt={appName}  />
            {/if}
            <h2 style="color: var(--f7-theme-color)">Manager portal</h2>
			<div class="splash-preloader">
				<span class="preloader preloader-yellow"></span>
			</div>
		</div>
		
	</Block>
	
</Page>
<script>
let appName,logo;

function onPageInit(){
    appName=f7.name;
    logo=f7.data.logo;

    window.setTimeout(()=>{
            f7.views.main.router.navigate({ name: 'home' });
            f7.$('.top-toolbar').show();
            
    },2000)
}

 import {f7,
    Page,
    Navbar,
    NavLeft,
    NavTitle,
    NavTitleLarge,
    NavRight,
    Link,
    Button,Block
  } from 'framework7-svelte';

</script>