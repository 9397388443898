
import HomePage from '../pages/home.svelte';
import SplashPage from '../pages/splash.svelte';
import AlcoholPage from '../pages/alcohol.svelte';
import WinePage from '../pages/wine.svelte';
import BrandsPage from '../pages/alcohol/brands.svelte';
import BrandSelPage from '../pages/alcohol/selbrands.svelte';
import FormPage from '../pages/form.svelte';
import CatalogPage from '../pages/catalog.svelte';
import ProductPage from '../pages/product.svelte';
import SettingsPage from '../pages/settings.svelte';
import TablesPage from '../pages/tables.svelte';
import DynamicRoutePage from '../pages/dynamic-route.svelte';
import RequestAndLoad from '../pages/request-and-load.svelte';
import NotFoundPage from '../pages/404.svelte';
import {handleAsyncLogin,loginAnonymously,silentAnonymousLogin } from '../components/dts-shared-components/auth/core'
import {loginStatus,openPopup,authChanged } from '../components/dts-shared-components/auth/store'

import {firebaseAuthLoaded } from '../components/dts-shared-components/firebase/store'

let isLoggedIn=false;


var routes = [
  {
    path: '/home/',
    name: 'home',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      authChanged.subscribe(isChanged => {
        if (isChanged){
          loginStatus.subscribe(status => {
            if (!status) openPopup.set(true);
            else{
              console.log("------------------------------------------home"); resolve({component: HomePage},{reloadCurrent: true});
            }
          });

          //openPopup.set(true);
        }
      });
      window.setTimeout(()=>{
          reject();
      },30000)
    }
  },
  {
    path: '/',
    component: SplashPage,
    name:'splash'
  },
  {
    path: '/alcohol/',
    component: AlcoholPage,
  },
  {
    path: '/wine/',
    component: WinePage,
  },
  {
    path: '/tables/',
    async: function(routeTo,routeFrom, resolve,reject) {
      var router = this;
      var app = router.app;
      firebaseAuthLoaded.subscribe(value => {
        if (value){
          silentAnonymousLogin(app)
          .then((user)=>{
              resolve({component: TablesPage},{reloadCurrent: true});
          })
        }
      });
      window.setTimeout(()=>{
          reject();
      },30000)
    }
  },
  {
    path: '/brands/',
    component: BrandsPage,
  },
  {
    path: '/brand-sel/:id/',
    component: BrandSelPage,
  },
  {
    path: '/catalog/',
    component: CatalogPage,
  },
  {
    path: '/product/:id/',
    component: ProductPage,
  },
  {
    path: '/settings/',
    component: SettingsPage,
  },

  {
    path: '/dynamic-route/blog/:blogId/post/:postId/',
    component: DynamicRoutePage,
  },
  {
    path: '/request-and-load/user/:userId/',
    async: function (routeTo, routeFrom, resolve, reject) {
      // Router instance
      var router = this;

      // App instance
      var app = router.app;

      // Show Preloader
      app.preloader.show();

      // User ID from request
      var userId = routeTo.params.userId;

      // Simulate Ajax Request
      setTimeout(function () {
        // We got user data from request
        var user = {
          firstName: 'Vladimir',
          lastName: 'Kharlampidi',
          about: 'Hello, i am creator of Framework7! Hope you like it!',
          links: [
            {
              title: 'Framework7 Website',
              url: 'http://framework7.io',
            },
            {
              title: 'Framework7 Forum',
              url: 'http://forum.framework7.io',
            },
          ]
        };
        // Hide Preloader
        app.preloader.hide();

        // Resolve route to load page
        resolve(
          {
            component: RequestAndLoad,
          },
          {
            context: {
              user: user,
            }
          }
        );
      }, 1000);
    },
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
