import {userToken} from '../components/dts-shared-components/auth/store'
import {reqService,postService} from '../components/dts-shared-components/core/functions'

import { writable} from 'svelte/store';
import { writable as pwritable } from 'svelte-persistent-store/dist/local';
import { expwritable } from './expstore.js';

let expiry=new Date(new Date().getTime() + 8*60*60*1000);

export const locTables=expwritable('JD_LocTables',null,expiry);
export const locId=expwritable('JD_lid',null,expiry);


let authToken=null;
userToken.subscribe(value => {
    authToken = value;
});

export async function getTables(id){
	//let qs="id=" + id;
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/getTables`)
	return reqService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/getTables',"","",true)
}


export async function getDrinks(ty){
	let qs="ty=" + ty;
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/drinks`)
	return reqService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/drinks',qs,"",true)
}

export async function getBrandsByType(type){
	let qs="ty=" + type;
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/getLocBrands`)
	return reqService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/getLocBrands',qs,"",true)
}

export async function updateLocBrand(id,data){
	let body=JSON.stringify({id,data});
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/ulocbrandlist`)
	return postService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/ulocbrandlist',body,"",false)
}

export async function sendChatEvent(action){
	let body=JSON.stringify({action});
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/chatev`)
	return postService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/chatev',body,"",false)
}

export async function getActiveTables(){
	//let qs="id=" + id;
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/activeTables`)
	return reqService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/activeTables',"","",true)
}

export async function initUser(){
	//let body=JSON.stringify({lid:lid,qrKey: qrKey,uid: uid,uname: uname, pic: pic,isShort,msgToken,avatar});
	let body="";
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/initUser`)
	return postService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/initUser',body,'',true)
}


export async function closeTable(id){
	//let body=JSON.stringify({lid:lid,qrKey: qrKey,uid: uid,uname: uname, pic: pic,isShort,msgToken,avatar});
	let body=JSON.stringify({id,isClosed: true});
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/updateTable`)
	return postService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/updateTable',body,'',true)
}

export async function updateDrinkStatus(tid,id,status){
	//let body=JSON.stringify({lid:lid,qrKey: qrKey,uid: uid,uname: uname, pic: pic,isShort,msgToken,avatar});
	let body=JSON.stringify({tid,id,status});
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/updateDrinkStatus`)
	return postService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/updateDrinkStatus',body,'',true)
}


export async function getTableDetails(id){
	let qs="id=" + id;
	console.log(`https://us-central1-just-drinks-manager-dev.cloudfunctions.net/tdetail`)
	return reqService('https://us-central1-just-drinks-manager-dev.cloudfunctions.net/tdetail',qs,"",true)
}

	