
import {f7} from 'framework7-svelte'
import {userToken} from '../auth/store'

let authToken=null;
userToken.subscribe(value => {
	authToken = value;
});
export async function postService(url,params,title,show){
    if (typeof params === 'object')
        params=JSON.stringify(params);

    if (show)
        if (title)
            f7.dialog.preloader(title);
        else
            f7.preloader.show();


    const options = {
            method: 'POST',
            body: params,
            headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authToken,
                }
        }
    return window.fetch(url, options)
    .then((response) => {
        if (show)
		    if (title) f7.dialog.close();
		    else
                f7.preloader.hide();
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then((data)=>{
        return data;
    })
    .catch((e)=>{
        f7.dialog.alert("Oops, something went wrong!")
        if (show)
		    if (title) f7.dialog.close();
		    else
		        f7.preloader.hide();
		throw new Error('error')
    })

}
export async function reqService(url,params,title,showLoader){

	if (showLoader){
		if (title)
		f7.dialog.preloader(title);
		else
	 	f7.preloader.show();
    }
    const options = {
            method: 'GET',
            headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + authToken,
                }
        }
    return window.fetch(url + "?" + params, options)
    .then((response) => {
		if (showLoader){
			if (title) f7.dialog.close();
			else
			f7.preloader.hide();
        }
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    })
    .then((data)=>{
        return data;
    })
    .catch((e)=>{
		f7.dialog.alert("Oops, something went wrong!");
		if (showLoader){
			if (title) f7.dialog.close();
			else
			f7.preloader.hide();
		}
		throw new Error('error')
    })

}