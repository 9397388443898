
let apiUrl="https://test.serve.chat";
import 'whatwg-fetch'
let module,session;



//*********************************************************************************************
//** InitStart
//**
//*********************************************************************************************
let started=false;
export async function initStart(cmodule,action,name,locid,userid,metaData,logging,utoken){
	if (!cmodule || !action){
		console.warn("** module and action required ***")
	}else{
		module=cmodule;
        if (started) return; 

		let body={module: cmodule,action: action, name: name};

		if (userid) body.userid=userid;
		if (locid) body.locid=locid;
		if (metaData) body.meta=metaData;

		if (logging == "true")
			console.log("start.body=" + JSON.stringify(body));
		//if (!welcome){
			try {
				const res=	await fetch(`${apiUrl}/api/v1/start`,
				{method:"POST",headers:{"Content-Type":"application/json",'Authorization': 'Bearer ' + utoken},
				body:JSON.stringify(body)});
				const response=await res.json();
				return response;
			  } catch (error) {
				return null;
				// expected output: ReferenceError: nonExistentFunction is not defined
				// Note - error messages will vary depending on browser
			  }
			  

			if (logging == "true"){
				//console.log("initStart, d=" + JSON.stringify(welcome));
			}
			
            
		//}
			
	}
}
/**********************************************************************************************
 * sendEvent
 * Sends silent event to bot
 */
export async function sendEventToBot(cmodule,session,action,meta,utoken,url){
	let body={module: cmodule,action: action, session: session,meta: meta};

	console.log(body)
	try {
		if (!url) url=`${apiUrl}/api/v1/event`;

		const res=	await fetch(url,
		{method:"POST",headers:{"Content-Type":"application/json",'Authorization': 'Bearer ' + utoken},
		body:JSON.stringify(body)});
		const response=await res.json();
		return response;
	  } catch (error) {
		return null;
	}
	  

}
//*********************************************************************************************
//** sendToBot - Send message to bot handler
//**
//*********************************************************************************************
export async function sendToBot(cmodule,session,msg,utoken,url){

	let body={module: module,session: session,text:msg};
	console.log('Send to bot',url)
	if (!url) url=`${apiUrl}/api/v1/messages`;
	const res=	await fetch(url,
	{method:"POST",headers:{"Content-Type":"application/json",'Authorization': 'Bearer ' + utoken},
	body:JSON.stringify(body)});
	const response=await res.json();
	return response;
}

