<App params={ f7params } themeDark>



  <!-- Right panel with reveal effect-->
  <Panel right reveal themeDark>
    <View>
      <Page>
        <Navbar title="Just Drinks"/>
        <Block>Manager Portal</Block>
        <List>
          <ListItem><Logout></Logout></ListItem>
        </List>
      </Page>
    </View>
  </Panel>


  <!-- Views/Tabs container -->
  <Views tabs class="safe-areas">
    <!-- Tabbar for switching views-tabs -->
    <Toolbar tabbar labels bottom style="display:none" class="top-toolbar">
      <Link tabLink="#view-home" tabLinkActive iconIos="f7:chart_bar" iconAurora="f7:dashboard" iconMd="material:dashboard" text="Dashboard" />
        <Link tabLink="#view-tables" iconIos="material:table" iconAurora="material:table" iconMd="material:table" text="Tables" />
      <Link tabLink="#view-alcohol" iconIos="material:local_bar" iconAurora="material:local_bar" iconMd="material:local_bar" text="Alcohol Manager" />
      <Link tabLink="#view-settings" iconIos="f7:gear" iconAurora="f7:gear" iconMd="material:settings" text="Settings" />
    </Toolbar>

    <!-- Your main view/tab, should have "view-main" class. It also has "tabActive" prop -->
    <View id="view-home" main tab tabActive url="/" />

     <!-- Catalog View -->
    <View id="view-tables" name="tables" tab url="/tables/" />

    <!-- Catalog View -->
    <View id="view-alcohol" name="catalog" tab url="/alcohol/" />

    <!-- Settings View -->
    <View id="view-settings" name="settings" tab url="/settings/" />

  </Views>


  <!-- Popup -->
  <Popup id="my-popup">
    <View>
      <Page>
        <Navbar title="Popup">
          <NavRight>
            <Link popupClose>Close</Link>
          </NavRight>
        </Navbar>
        <Block>
          <p>Popup content goes here.</p>
        </Block>
      </Page>
    </View>
  </Popup>
 <NetworkCheck on:isOnline={networkOnline}></NetworkCheck>

  <FireBase config={firebaseAppConfig} loadScripts={true}></FireBase>
   <Login title="Just Drinks! Manager" appUrl={appUrl} on:loggedIn={loggedIn} mode="password" userAnonymous={false} required={true}>
    <div slot="headingContent">
      <BlockTitle class="text-align-center">Just Drinks! Manager portal</BlockTitle>
    </div>
  </Login>

</App>
<script>
  import { onMount } from 'svelte';
 import {firebaseAppConfig} from '../js/firebase-config' // App firebase info
import FireBase from './dts-shared-components/firebase/firebase.svelte'
import {firestoreInstance} from './dts-shared-components/firebase/store'
import Login from '../components/dts-shared-components/auth/login.svelte';
import Logout from '../components/dts-shared-components/auth/logout.svelte';
import NetworkCheck from './dts-shared-components/network/network.svelte'
  import {
    f7, 
    f7ready,
    App,
    Panel,
    Views,
    View,
    Popup,
    Page,
    Navbar,
    Toolbar,
    NavRight,
    Link,
    Block,
    BlockTitle,
    LoginScreen,
    LoginScreenTitle,
    List,
    ListItem,
    ListInput,
    ListButton,
    BlockFooter
  } from 'framework7-svelte';

  import routes from '../js/routes';

  
function networkOnline(e){
  let d=e.detail;
  f7ready(()=>{
    if (!d.isOnline){
       f7.dialog.alert("No network connection was found. Please check your wifi settings.")
    }else{
      // load firebase component
      console.log("imported firebase component")
    }
  })
 
}

  function loggedIn(e){
      let userRef=$firestoreInstance.collection(`users`);
     userRef.doc(e.detail.uid).get()
     .then((qs)=>{
       if (qs.exists){
            userRef.doc(e.detail.uid).set({ manager: true, lid:'1Q48TjsIvD12dUZQH185',business:true},{merge:true})
              .then(()=>{
            });
         //ui.id=e.detail.uid;
         //avatar=ui.defaultAvatar;
         //userInfo.set(ui);
       }else{
            let ui={created: new Date(),themeDark: true,business: true, manager: true, lid:'1Q48TjsIvD12dUZQH185'};
              userRef.doc(e.detail.uid).set(ui)
              .then(()=>{
                userInfo.set(ui);
            });

       }
     })
  }

    let appUrl="https://manager.justdrinks.app/";

   if(window.location.href.indexOf("localhost") > -1) {
     appUrl="http://localhost:5001/";
   }
 

  // Framework7 Parameters
  let f7params = {
    name: 'Just Drinks - Manager Portal', // App name
    theme: 'auto', // Automatic theme detection

    // App root data
    data: function () {
      return {
        logo: 'https://img.imageboss.me/justdrinks/width/300/logo.png',
      };
    },

    // App routes
    routes: routes,
    // Register service worker
    serviceWorker: {
      path: '/service-worker.js',
    },
  };
  // Login screen demo data
  let username = '';
  let password = '';

 
  onMount(() => {
    f7ready(() => {

      // Call F7 APIs here
    });
  })
</script>