/* Persistent store */
import { writable, readable, derived } from 'svelte-persistent-store/dist/local';

export function expwritable(name,value,exp){
    let nexp=localStorage.getItem(name+'-exp');
    if (nexp != null){
        let expDate=new Date(nexp);
        if (expDate < new Date()){
            localStorage.removeItem(name)
            localStorage.removeItem(name+'-exp')
            localStorage.setItem(name+'-exp',exp.toString());
            return writable(name,value)
        }
    }else{
        if (exp)
            localStorage.setItem(name+'-exp',exp.toString());
    }
    return writable(name,value);
}


